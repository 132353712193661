<template>
    <div class="container" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">

        <el-row :gutter="20">
            <el-col :span="12">
                <el-card class="box-user">
                    <el-row>
                        <el-col :span="8">
                            <div class="user-img">
                                <!--<img src="../../../assets/img/user.png" width="30px" alt="">-->
                                <div>{{ vipinfo.username }}</div>
                                <!--<span>{{ vipinfo.parents }}</span>-->
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <span>账号:</span>
                            <span>{{ vipinfo.phone }}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>手机:</span>
                            <span>{{ vipinfo.phone }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <span>城市:</span>
                            <span>{{vipinfo.address2 || " -- " }}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>注册时间:</span>
                            <span>{{ vipinfo.ctime | foramtDate }}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>最近登录:</span>
                            <span>{{ vipinfo.logindate | foramtDate  }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <span>登录次数:</span>
                            <span>{{ vipinfo.num }}</span>
                        </el-col>
                        <el-col :span="8">
                            <span>日均次数:</span>
                            <span>{{ vipinfo.numbers }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <span>常见地址:</span>
                        <span>{{ vipinfo.address2  + vipinfo.address }}</span>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12" >
                <el-card class="box-user">
                    <el-row>
                        <el-col :span="8">
                                <span>所属渠道:{{vipinfo.qname}}</span>
                                <div>渠道账号:{{ vipinfo.qdloginname }}</div>
                                <div>关注渠道:{{ vipinfo.gznum }}</div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

        <Child  :name="index" :kiid="kiid" @useInfo="getVipInfo" ></Child>

<!--        <div class="floater-wrap" ref="floater"  @click="dialogVisible=true">-->
<!--            <el-badge :value="count" :max="99" class="item">-->
<!--                <img src="../../../assets/img/msg_sys.png" alt="">-->
<!--            </el-badge>-->
<!--        </div>-->

<!--        <el-dialog title="客户服务" :visible.sync="dialogVisible" -->
<!--        :close-on-click-modal="false"-->
<!--        :close-on-press-escape="false" >-->
<!--            <div class="hang">-->
<!--                <div class="hang-left">-->
<!--                    <Service ref="service" :uid="uid"></Service>-->
<!--                </div>-->

<!--                <div class="right">-->
<!--                    <div class="hang1">-->
<!--                        <el-input v-model="search" size="mini" placeholder="输入关键字搜索"/>-->
<!--                        <el-button slot="append" icon="el-icon-search" size="mini" @click="updateList" >搜索</el-button>-->
<!--                    </div>-->

<!--                    <el-table :data="tableData" style="width: 100%;height:calc(100% - 60px);overflow-y:auto;margin-top:10px" stripe :size='tabsize'-->
<!--                    :default-sort="{prop: 'date', order: 'descending'}" border>-->
<!--                        <el-table-column label="图标" prop='loginname' align='center'>-->
<!--                            <template slot-scope="scope">-->
<!--                                <img :src="scope.row.imgurl" width="35px" height="35px" alt="">-->
<!--                                &lt;!&ndash; <span>{{ scope.row.imgurl }}</span> &ndash;&gt;-->
<!--                            </template>-->
<!--                        </el-table-column>  -->
<!--                        <el-table-column label="课程标题" prop='username' align='center'>-->
<!--                            <template slot-scope="scope">-->
<!--                                <span>{{ scope.row.zname }}</span>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column label="课程简介" prop='phone' align='center'>-->
<!--                            <template slot-scope="scope">-->
<!--                                <span>{{ scope.row.intro }}</span>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column align="center">-->
<!--                        -->
<!--                        <template slot-scope="scope">-->
<!--                            <el-button-->
<!--                            size="mini"-->
<!--                            @click="kfMsgSendAct(scope.$index, scope.row)">发送</el-button>-->
<!--                        </template>-->
<!--                        </el-table-column>-->
<!--                    </el-table>-->
<!--                    <el-pagination  @size-change="handleSizeChange"  @current-change="handleCurrentChange" :current-page.sync="currentPage" -->
<!--                        :page-size="5"  layout="total, prev, pager, next" :total="tableData.length">-->
<!--                    </el-pagination>-->
<!--                </div>-->
<!--            </div>-->
<!--        </el-dialog>-->
    </div>
</template>

<script>
    // import "animate.css"
    import Service from './Service.vue';
    import Child from './ChildInfo.vue';
    import dayjs from 'dayjs';
    export default {
        components: {
            Child,Service
        },
        data() {
            return {
                loading: false,
                activeName: 0,
                vipinfo: {},
                childList: [],
                kiid: 0,
                childShow:false,
                isShow:true,
                dialogVisible: false,
                tableData: [],
                search:"",
                currentPage: 1, //默认显示第一页
                pageSize: 5, //默认每页显示10条
                totalNum: '', //总页数
                order: '',
                sort: '',
                show: true,
                coordinate: {
                    client: {},
                    elePosition: {}
                },
                count:'',
                qdname:"瑞士",
                index: ''
            }
        },
        created() {
            this.kiid = this.$route.query.kiid;
            console.log('kiid: ' ,this.kiid);
            this.updateList()
            this.getVipInfo()
        },

        methods: {
            getVipInfo() {
                // 获取用户基本信息
                this.$api.vip.getVipInfo({
                    kiid: this.kiid
                }).then(res => {
                    console.log('vipinfo==>',res)
                    this.vipinfo = res.obj;
                })
            },
            handleClick(tab, event) {
            },
            handleClose(done) {
                    this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },

            updateList() {
                this.$api.article.updateList({
                    'jPage.pageNum': this.currentPage,
                    'jPage.pageSize': this.pageSize,
                    'jPage.sort': this.sort,
                    'jPage.order': this.order,
                    'zname': this.search,
                }).then(res => {
                    this.tableData = res.obj.list //获取数据列表
                    this.totalNum = res.obj.totalPage // 总页数
                    this.tableData.length = res.obj.totalRow //总条数
                    this.currentPage = res.obj.pageNumber //当前页码数
                }).catch((error) => {
                    console.log(error);
                })
            },

                  // 分页大小
            handleSizeChange(val) {
                this.loading = true
                this.pageSize = val
                this.currentPage = 1 // 选择分页大小后跳转第一页
                this.updateList()
            },
            // 第几页
            handleCurrentChange(val) {
                this.loading = true
                this.currentPage = val
                this.updateList()
            },
            closeFloater() {
                this.show = false;
            },
            kfMsgSendAct( index, row ){
                this.$refs.service.sendText( row.path );
            }
        },
        filters:{
            foramtDate(val){
                return val && dayjs(val).format('YYYY-MM-DD')
            }
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    }
</script>

<style lang="less" scoped>
    .container {
        width: 100%;
        overflow-y: auto;
        padding: 15px;
    }

    .box-user  {
        height: 160px;
    }

    .box-user .el-row {
        // height: 30px;
        line-height: 30px;
    }

    .el-tabs--border-card {
        box-shadow: none;
        border: none;
    }

    /deep/.el-tabs--border-card>.el-tabs__header {
        background-color: #eee;
    }

    .user-img {
        display: flex;
        align-items: center;
    }

    .user-img>div {
        font-size: 16px;
        font-weight: bold;
    }

    .user-img>span {
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        padding: 0px 6px;
        border-radius: 3px;
        background: #6dbf7d;
        color: #fff;
    }

    .mb20 {
        margin-bottom: 20px;
    }

    .el-tabs--border-card {
        background: none;
    }

    .el-tabs--border-card/deep/.el-tabs__content {
        padding: 0 15px 15px;
    }

     .sliderbar {
            position: fixed;
            right: 0;
            bottom: 100px;
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            color: #fff;
        }
        
        .con {
            position: absolute;
            left: 0;
            top: 0;
            width: 200px;
            height: 40px;
            background-color: purple;
            z-index: -1;
        }



.hang{
   position: relative;
   height: 50vh;
}

.hang-left{
    width: 30%;
}

.right{
    width: 64%;
    height: 100%;
    position: absolute;
    right: 0;
    text-align: center;
    color: #fff;
}

.hang1{
   display: flex;
}

.tt{
    background-color:#53a8ff;
    border-radius: 0.5rem;
    padding: .5rem 1.2rem;
}


.search-input {
    margin-top: 1vw;
    margin-right: 1vw;
    width: 60% ;

}

 .floater-wrap {
        width: 3.69vw;
        height: 3.69vw;
        position: fixed;
        right: 3vw;
        top: 45vw;
        z-index: 99;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .close {
        width: 1vw;
        height: 1vw;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;

        img {
            width: 100%;
            height: 100%;
            z-index: 99;
        }
    }



</style>