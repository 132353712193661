<template>
    <div class="conclusion" >
        <div class="center">
            日期：{{ formatDate(info.ctime) }}
        </div>
        <el-row>
            <el-col :span='12' class="ctitle" >基本信息</el-col>
        </el-row>
        <el-row class="mar10" >
            <el-col :span='8' >年龄：<span>{{ formatAge(info.age) }}</span></el-col>
            <el-col :span='8' >性别：<span>{{ info.sex ==1? '男' : info.sex == 2 ? '女' : '--' }}</span></el-col>
            <el-col :span='8' >身高：<span>{{ info.height + 'cm' }}</span></el-col>
        </el-row>
        <el-row class="mar10">
            <el-col :span="12" >
                <div class="center" >左眼</div>
                <el-row>
                    <el-col :span='12' >
                        裸眼：{{ info.裸眼视力左 || '--' }}
                    </el-col>
                    <el-col :span='12' >
                        屈光度：{{ info.屈光度左 || '--' }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span='12' >
                        散光：{{ info.散光度数左 || '--' }}
                    </el-col>
                    <el-col :span='12' >
                        轴长：{{ info.眼轴长度左 || '--' }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12" >
                <div class="center" >右眼</div>
                <el-row>
                    <el-col :span='12' >
                        裸眼：{{ info.裸眼视力右 || '--' }}
                    </el-col>
                    <el-col :span='12' >
                        屈光度：{{ info.屈光度右 || '--' }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span='12' >
                        散光：{{ info.散光度数右 || '--' }}
                    </el-col>
                    <el-col :span='12' >
                        轴长：{{ info.眼轴长度右 || '--' }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <div  >
            <span>近视程度：</span>
            <span>{{ info.近视程度 }}</span>
        </div>
        <div  >
            <span>近视风险：</span>
            <span>{{ info.近视风险 }}</span>
        </div>
        <div>
            {{info.csita}}
        </div>

        <div class="mar10">
            <p class="ctitle" >原因评估</p>
            <div>
                {{ressonStr}}
            </div>
        </div>
        <div class="mar10">
            <p class="ctitle" >专家建议</p>
            <div>
                {{ info.expr }}
            </div>
        </div>

        <div>
            <p class="ctitle" >具体原因</p>
            <div v-for="(item, index) in reason" :key="index"  class="mar10">
                <el-row class="mar5" >
                    <el-col :span='16' >{{ item.title }}</el-col>
                    <el-col :span='8' > {{ item.answer + '%' }} </el-col>
                </el-row>
                <el-row> {{ item.qufunname }} </el-row>
            </div>
        </div>

    </div>
</template>
<script>
    import dayjs from 'dayjs';
export default {
    props: ['info'],
    data() {
        return {
            reason:[]
        }
    },
    mounted() {
        this.reason = JSON.parse(this.info.eaforasse)
    },
    computed:{
        ressonStr(){
            if(this.reason.length < 1) return;
            let arr = [];
            for (let i = 0; i < this.reason.length; i++) {
                let str = this.reason[i].title + this.reason[i].answer + '%'
                arr.push(str)
            }
            console.log(arr);
            return '近视原因可能为：' + arr.join('，')
        }
    },
    methods:{
        formatAge(val){
            return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') :''
        },
        formatDate(val){
            return dayjs(val).format('YYYY年MM月DD日')
        }
    }
}
</script>
<style  scoped >
    .center{
        text-align: center;
    }
    .mar10{
        margin: 10px 0;
    }
    .mar5{
        margin: 5px 0;
    }
    .ctitle{
        font-size: 16px;
        font-weight: bold;
    }
</style>