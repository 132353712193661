// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {
    LineChart
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    DatasetComponentOption,
    TransformComponent,
    MarkLineComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {
    LabelLayout,
    UniversalTransition
} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
    CanvasRenderer
} from 'echarts/renderers';

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    TransformComponent,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    MarkLineComponent
]);

// 接下来的使用就跟之前一样，初始化图表，设置配置项
/**
 * 
 * @param {* dom元素id } id 
 * @param {* 线条颜色 } color 
 * @param {* 年龄数组 } ages 
 * @param {* 真实状态数组 } data1 
 * @param {* 标准值 } data2 
 * @param {* 理论状态数组 } data3 
 */
var myChart

function initChart(id, title, legend, ages, series, ymax, ymin) {
    // if (myChart != null && myChart != "" && myChart != undefined) {
    //     myChart.dispose(); //销毁
    // }
    if (document.getElementById(id) == null) {
        return
    }
    myChart = echarts.init(document.getElementById(id));
    let options = {
        title: [{
                text: '单位：',
                top: '5%',
                left: '3%',
                textStyle: {
                    fontSize: '13',
                    color: '#6a6a6a'
                },
            },
            {
                text: title,
                top: '5%',
                left: '50',
                textStyle: {
                    fontSize: '13',
                    color: '#6a6a6a'
                },
            }
        ],
        grid: {
            left: '10%',
            top: '20%',
            bottom: '20%',
            right: '15%'
        },
        legend: {
            data: legend,
            top: '5%',
            right: '5%',
            icon: 'roundRect',
            itemWidth: 18,
            itemHeight: 10
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ages,
            axisLabel: {
                rotate: 60,
                align: "right",
                fontSize: 10,
                formatter: (a) => {
                    return Math.floor(a / 12) + '岁' + ((a % 12) == 0 ? '' : (a % 12) + '个月')
                }
            },
            axisLine: {
                onZero: false,
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            max: ymax,
            min: ymin,
        },
        series
    }
    // setTimeout(() => {
    //     try {
    //         myChart.setOption(options, true)
    //     } catch (error) {}
    // }, 500)
    myChart.setOption(options)
}

function initChart1(id, title, legend, ages, series, ymax, ymin) {
    // if (myChart != null && myChart != "" && myChart != undefined) {
    //     myChart.dispose(); //销毁
    // }
    if (document.getElementById(id) == null) {
        return
    }
    myChart = echarts.init(document.getElementById(id));
    let options = {
        title: [{
            text: '得分',
            top: '5%',
            left: '3%',
            textStyle: {
                fontSize: '13',
                color: '#6a6a6a'
            },
        }, ],
        grid: {
            left: '10%',
            top: '20%',
            bottom: '20%',
            right: '15%'
        },
        // legend: {
        //     data: legend,
        //     top: '5%',
        //     right: '5%',
        //     icon: 'roundRect',
        //     itemWidth: 18,
        //     itemHeight: 10
        // },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ages,
            axisLabel: {
                rotate: 60,
                align: "right",
                fontSize: 10,
                formatter: (a) => {
                    return '第' + a + '周'
                }
            },
            axisLine: {
                onZero: false,
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            max: ymax,
            min: ymin,
        },
        series
    }
    // setTimeout(() => {
    //     try {
    //         myChart.setOption(options, true)
    //     } catch (error) {}
    // }, 500)
    myChart.setOption(options)
}
export {
    initChart,
    initChart1
};