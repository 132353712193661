<template>
    <div class="child" v-loading="loading1 || loading2 || loading3 || loading4 || loading5" element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.95)">
    
        <el-row  class="flex1">
            <el-col :span="8" class="bgwhite hight600" style="margin: 10px 10px 0px 0">
                <div class="margin10  child-info ">
                    <el-row>
                        <div class="user-img">
                            <!-- <img src="../../../assets/img/user.png" width="30px" alt=""> -->
                            <div style="white-space: nowrap;">{{info.kname || '--' }}</div>
                        </div>
                        <span class="sex">{{info.sex == 1 ? "男孩" : "女孩"}}</span>
                        <span class="sex">{{ info.age | comAge }}</span>
                        <!-- <span class="address">{{ info.province }}</span> -->
                        <span class="sex">    出生日期:{{ formatTime(info.birthdata) }}</span>
                        <!-- <span class="sex"> 属相:{{ info.属相 || '--' }} </span> -->
                        <el-link :underline="false" type="success" @click="userinfo">编辑</el-link>
                    </el-row>
                    <div class="rows flex1">
                        <div>
                            身份证号:{{ info.card || '--' }}
                        </div>
                        <div>
                            身高:{{ info.height || '--' }}
                        </div>
                        <div>
                            体重:{{ info.weight || '--' }}
                        </div>
                        <div>
                            体质:{{ info.bmi || '--' }}
                        </div>
                    </div>
                    <!-- <div class="rows flex">
                        <div>
                            学校：<span>是否落实到分类定好了</span>
                        </div>
                        
                    </div> -->
                    <div class="rows eyes">
                        <img :src="eyes" class="tu">
                        <div>
                            <div class="tongju">
                                瞳距:{{ info.ljpd }}
                            </div>
                            <div class="tonggao">
                                瞳高:{{ info.ljtg }}
                            </div>
                        </div>
                        
                    </div>
                    <el-row>
                        <el-col :span="10" >
                            <div>
                                <span>裸眼: {{ info.裸眼视力左 || '--' }} </span>
                                <span>近视: {{ info.屈光度左 || '--' }} </span>
                            </div>
                            <div>
                                <span>散光: {{ info.散光度数左 || '--' }} </span>
                                <span>轴长: {{ info.眼轴长度左 || '--' }} </span>
                            </div>
                        </el-col>
                        <el-col :span="10" :offset="4" >
                            <div>
                                <span>裸眼: {{ info.裸眼视力右 || '--' }} </span>
                                <span>近视: {{ info.屈光度左 || '--' }} </span>
                            </div>
                            <div>
                                <span>散光: {{ info.散光度数右 || '--' }} </span>
                                <span>轴长: {{ info.眼轴长度右 || '--' }} </span>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="rows flex">
                        <div style="margin-right:38px;">
                            <span class="label">同龄排行： </span>
                            <span class="value">{{ info.rankings || '--' }}名</span>
                        </div>
                            
                        <span>竞争力: -  </span>
                    
                    </div>
                    <div class="rows">
                        <span class="label">目标管控： </span>
                        <span class="value"> - </span>
                    </div>
                    <div class="rows">
                        <span class="label">视力风险： </span>
                        <span class="value">{{ info.diopter || '--' }}</span>
                    </div>
                    <div class="rows">
                        <span class="label">视力程度： </span>
                        <span class="value">{{ info.myopia || '--' }}</span>
                    </div>
                    <div class="rows high">
                        <span class="label">专家建议： </span>
                        <span class="value">{{ info.expertProposes || '--' }}</span>
                    </div>
                    <div class="rows">
                        <span class="label">当前趋势： </span>
                        <span class="value">{{ info.currentTrend || '--' }}</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="8" class="bgwhite hight600" style="margin: 10px 10px 0px 0px">
                <div class="margin10  record  child-info">
                    <div class="record-left-btn" @click="recLeftClick"> </div>
                    <div class="record-right-btn" @click="recRightClick"> </div>
                    <el-row>
                        <div class="head-title dangan-title">
                            <div>
                                <span>屈光档案</span>
                                <span class="label" style="color: #666;font-size: 13px;">({{ num + 1 }}/{{ nums }})</span>
                            </div>
                            <div >
                                <el-link :underline="false" type="success" @click="lookTable">查看表格</el-link>&nbsp;&nbsp;&nbsp;&nbsp;
                                <el-link :underline="false" type="success" @click="add">添加</el-link>&nbsp;&nbsp;&nbsp;&nbsp;
                                <el-link :underline="false" type="success" @click="edit">编辑</el-link>
                            </div>
                        </div>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <span>验光时间：</span>
                            <span v-if="this.actInfo.checktimes">{{ (this.actInfo.checktimes ? formatTime(this.actInfo.checktimes) : '--') }}</span>
                            <span v-else>--</span>
                        </el-col>
                        <el-col :span="12">
                            <span>地点：</span>
                            <span>{{ this.actInfo.htype |  visadress}}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="5">
                            <span>年龄：</span>
                            <span>{{ this.actInfo.age ? formatAge(this.actInfo.age) : '-' }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span>身高：</span>
                            <span>{{ this.actInfo.height ? this.actInfo.height : '-' }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span>体重：</span>
                            <span>{{ this.actInfo.weight ? this.actInfo.weight : '-' }}</span>
                        </el-col>
                        <el-col :span="5">
                            <span>体质：</span>
                            <span>{{ this.actInfo.bmi ? this.actInfo.bmi : '-' }}</span>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col class="bold" :span="5" :offset="6" align="center"> 左眼 </el-col>
                        <el-col class="bold" :span="5" align="center"> 右眼 </el-col>
                        <el-col class="bold" :span="5" align="center"> 参考范围 </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 裸眼视力： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.裸眼视力左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.裸眼视力右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.裸眼参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 矫正视力： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.矫正视力左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.矫正视力右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.矫正参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 视力评价： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.视力评价左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.视力评价右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.视力评价参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 小瞳球镜： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.屈光度左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.屈光度右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.屈光度参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 轴屈光度： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.轴屈光度左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.轴屈光度右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.轴屈光度参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 小瞳柱镜： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.柱镜左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.柱镜右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.柱镜参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 散光轴位： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散光轴位左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散光轴位右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散光轴位参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 散瞳球镜： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散瞳屈光度左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散瞳屈光度右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散瞳屈光度参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 散瞳柱镜： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散瞳柱镜左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散瞳柱镜右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.散瞳柱镜参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 复验球镜： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.复验球镜左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.复验球镜右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.复验球镜参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 复验柱镜： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.复验柱镜左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.复验柱镜右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.复验柱镜参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 眼轴轴长： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.眼轴长度左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.眼轴长度右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.眼轴长度参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 临界眼轴： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.临界眼轴左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.临界眼轴右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.临界眼轴参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 轴余： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.轴余左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.轴余右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.轴余参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 角膜曲率K1： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜曲率左k1 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜曲率右k1 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜曲率左参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 角膜曲率K2： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜曲率左k2 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜曲率右k2 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜曲率右参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 角膜散光： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜散光左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜散光右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.角膜散光参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 月化增速： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.月化增速左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.月化增速右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.月化增速参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 眼压： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.眼压左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.眼压右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.眼压参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> 中央角膜厚度： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.中央角膜厚度左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.中央角膜厚度右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.中央角膜厚度参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> ACD： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.ACD左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.ACD右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.ACD参考 }} </el-col>
                    </el-row>
                    <el-row >
                      <el-col :span="6" align="center"> LT： </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.LT左 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.LT右 }} </el-col>
                      <el-col :span="5" align="center"> {{ this.actInfo.LT参考 }} </el-col>
                    </el-row>

                    <div style="margin:20px 0">
                      <span style="width :100% ;font-size: 16px;font-weight: bold">档案预警： </span>
                      <div style="font-size: 16px;line-height: 28px;margin-top: 20px;">
                          <span>{{ zjwarn }}</span>
                      </div>
                      <div style="font-size: 14px;font-weight: bold; margin: 25px 15px 0 0;" align="right">
                            眼科医生：甄毅
                      </div>
                    </div>
                    <el-row :gutter="15" type="flex" style="flex-flow: wrap;">
                        <el-col :span="6" v-for="(item, index) in recordImgs" :key="index">
                            <el-image style="width: 100px; height: 60px" :src="item" :preview-src-list="recordImgs"> </el-image>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="8" style="margin: 10px 0 0px 0px">
                <div class="height300 mb20  pd20 bgwhite">
                    <el-row>
                        <div class="head-title mb20">
                            <span>矫正记录</span>
                        </div>
                        <ul class="histroy-list">
                            <li type="flex"  style="margin-bottom:15px">
                                <div class="width1"><span><i class="dian_white"></i></span></div>
                                <div class="width4"><span>方案</span></div>
                                <div class="width3"><span>开始时间</span></div>
                                <div class="width2"><span>时长</span></div>
                            </li>
                            <li type="flex" class="height30" v-for="(item, index) in corrList" :key="index">
                                <div class="width1"><span><i class="dian"></i></span></div>
                                <div class="width4"><span class="names">{{ item.corname }}</span></div>
                                <div class="width3"><span class="dates">{{ item.rtime | formatTimes }}</span></div>
                                <div class="width2"><span class="dates">{{ item.subtime }}</span></div>
                            </li>
                            <p v-if="corrList.length > 0">—— 已加载全部 ——</p>
                            <p v-if="corrList.length <= 0">—— 无数据 ——</p>
                        </ul>   
                    </el-row>
                </div>
                <div class="height300 pd20 bgwhite">
                    <el-row>
                        <div class="head-title mb20">
                            <span>近视诱因评估结果</span>
                        </div>
                        <ul class="histroy-list">
                        <li type="flex" class="height30 mb20" v-for="(item, index) in ssicaList" :key="index"
                            @click="lookConclusion(item)">
                                <span class="names fonthidden ">
                                    {{ item.csita }}
                                </span>
                            <span class="dates">
                                    {{ item.ctime | formatTimes }}
                                    <i class="el-icon-arrow-right"></i>
                            </span>
                        </li>
                        <p v-if="ssicaList.length > 0">—— 已加载全部 ——</p>
                        <p v-if="ssicaList.length <= 0">—— 无数据 ——</p>
                        </ul>
                    </el-row>
                    
                    <!-- <ul class="histroy-list">
                        <li class="height30">
                            {{ '角膜陡峭，近视风险较高' }}  {{ '10% '}}
                        </li>
                        <li class="height30">
                            {{ '角膜陡峭，近视风险较高' }}  {{ '10% '}}
                        </li>
                        <li class="height30">
                            {{ '角膜陡峭，近视风险较高' }}  {{ '10% '}}
                        </li>
                        <li class="height30">
                            {{ '角膜陡峭，近视风险较高' }}  {{ '10% '}}
                        </li>
                        <li class="height30">
                            {{ '角膜陡峭，近视风险较高' }}  {{ '10% '}}
                        </li>
                        <li class="height30">
                            {{ '角膜陡峭，近视风险较高' }}  {{ '10% '}}
                        </li>
                    </ul> -->

                </div>
                <div class="height300 mb20  pd20 record bgwhite" style="margin-top:10px">
                    <div class="record-left-btn" @click="recLeftClick1"> </div>
                    <div class="record-right-btn" @click="recRightClick1"> </div>
                    <el-row>  
                        <div class="flex1">
                            <div class="head-title  mb20">
                                <span>当前离焦镜方</span>
                            </div>
                            <div>{{ this.actInfo.checktime | formatTimes }}</div>
                        </div>
                        <ul class="histroy-list1">
                            <li style="margin-bottom:10px">
                                <span style="width:100%">品牌：{{ this.actInfo.ljpt }}</span>
                            </li>
                            <li type="flex"  style="margin-bottom:20px">
                                <span style="width:25%">眼别</span>
                                <span style="width:25%">球镜</span>
                                <span style="width:25%">柱镜</span>
                                <span style="width:25%">轴位</span>
                            </li>
                            <li  style="margin-bottom:20px">
                                <span style="width:25%">左眼</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.屈光度左 }}</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.柱镜左 }}</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.散光轴位左 }}</span>
                            </li>
                            <li  style="margin-bottom:20px">
                                <span style="width:25%">右眼</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.屈光度右 }}</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.柱镜右 }}</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.散光轴位右 }}</span>
                            </li>
                            <li  class="listart"  style="margin-bottom:20px">
                                <span style="width:25%">瞳距</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.瞳距 }}</span>
                                <span style="width:25%"></span>
                                <span style="width:25%"></span>
                            </li>
                            <li>
                                <span style="width:25%">瞳高</span>
                                <span style="width:25%">{{ this.actInfo.ljpt == null || this.actInfo.ljpt == '' ? '-' : this.actInfo.瞳高 }}</span>
                                <span style="width:25%"></span>
                                <span style="width:25%"></span>
                            </li>
                        </ul>
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="10">
            <el-col :span="8" >
                <div class="height300" >
                    <div class="chart-title">
                        <span>裸眼视力趋势</span>
                    </div>
                    <div id="lyslChart"></div>
                    <!-- <el-row type="flex" justify="space-around">
                        <el-link>裸眼</el-link>
                        <el-link>屈光度</el-link>
                        <el-link>散光</el-link>
                        <el-link>轴长</el-link>
                    </el-row> -->
                </div>
            </el-col>
            <el-col :span="8" >
                <div class="height300">
                    <div class="chart-title">
                        <span>屈光度发育趋势</span>
                    </div>
                    <div id="qgdChart"></div>
                    <!-- <el-row type="flex" justify="space-around">
                        <el-link>裸眼</el-link>
                        <el-link>屈光度</el-link>
                        <el-link>散光</el-link>
                        <el-link>轴长</el-link>
                    </el-row> -->
                </div>
            </el-col>
            <el-col :span="8" >
                <div class="height300">
                    <div class="chart-title">
                        <span>眼轴发育趋势</span>
                    </div>
                    <div id="yzChart"></div>
                    <!-- <el-row type="flex" justify="space-around">
                        <el-link>裸眼</el-link>
                        <el-link>屈光度</el-link>
                        <el-link>散光</el-link>
                        <el-link>轴长</el-link>
                    </el-row> -->
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="20" class="height300 mb20">
            <el-col :span="16">
                <div class="height300 bgwhite">
                    <div class="chart-title">
                        <span>训练成就趋势</span>
                    </div>
                    <div id="achiveChart"></div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="height300 record bgwhite">
                    <div class="record-left-btn" @click="recLeftClick"> </div>
                    <div class="record-right-btn" @click="recRightClick"> </div>
                    <el-row>
                        <div class="achive-titles">
                            <span>训练成就</span>
                        </div>
                        <div class="achive-view">
                        <div class="achive-title" v-if=" achiveInfo.list && achiveInfo.list.length > 0">
                            <div class="achive-title-left">
                                <div>
                                    <p>第{{ achiveInfo.cweeks || '' }}周</p>
                                    <p>成就值</p>
                                </div>
                                <div class="achive-num">
                                    <div class="achive-ranking">{{ achiveInfo.acmvalue || '' }}</div>
                                    <div>排名：{{ achiveInfo.ranking || '' }}</div>
                                </div>
                            </div>
                            <div class="achive-date">
                                {{ achiveInfo.inmonth }}
                            </div>
                        </div>
                        <div class="achive-list" v-if=" achiveInfo.list && achiveInfo.list.length > 0">
                            <div v-show="achPage == 1">
                                <div v-for="(item, index) in achiveInfo.list" :key="index">
                                    <el-row v-if="index < 6">
                                        <el-col :span="8">{{ item.title || '' }}</el-col>
                                        <el-col :span="8"> {{ item.fenzhi || '' }} </el-col>
                                        <el-col :span="8" align="left"> {{ Number(item.baifenbi)*100 + "%" }} </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div v-show="achPage == 2">
                                <div v-for="(item, index) in achiveInfo.list" :key="index">
                                    <el-row v-if="index >= 6">
                                        <el-col :span="8">{{ item.title || '' }}</el-col>
                                        <el-col :span="8"> {{ item.fenzhi || '' }} </el-col>
                                        <el-col :span="8" align="left"> {{ Number(item.baifenbi)*100 + "%" }} </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </div>
                        <div class="achive-page" v-if=" achiveInfo.list && achiveInfo.list.length > 0">
                            <button @click="achPage = 1" :class=" achPage == 1 ? 'ach-active' : '' ">1</button>
                            <button @click="achPage = 2" :class=" achPage == 2 ? 'ach-active' : '' ">2</button>
                        </div>
                    </div>
                    </el-row>
                    
                </div>
            </el-col>
        </el-row>
        
        <el-row :gutter="20" class="height300 mb20">
            <el-col :span="16">
                <div class="height300  bgwhite">
                    <div class="chart-title">
                        <span>视功能报告</span>
                    </div>
                    <div id="achiveChart1" style="height:100%"></div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="height300 record  bgwhite">
                    <div class="record-left-btn" @click="recLeftClick"> </div>
                    <div class="record-right-btn" @click="recRightClick"> </div>
                    <el-row>
                        <div class="achive-titles">
                            <span>视功能训练成就</span>
                        </div>
                    </el-row>
                    <div class="achive-view">
                    </div>
                </div>
            </el-col>
        </el-row>

        <div class="achive-titles" style="height: 40px;">
            <span>活动审核</span>
        </div>
         <el-table :data="tableData" height="500px" :size="tabsize" border stripe style="width:100%;overflow:auto" v-loading="loading5" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
            <el-table-column prop="id" label="序号" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="活动名称" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.address }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="ctime" label="报名时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.ctime }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="活动状态" align="center">
                <template slot-scope="scope">
                    <span :class="{'dsta': scope.row.status == 10, 'ysta': scope.row.status == 20, 'wsta': scope.row.status == 30}">
                        {{ scope.row.status | astatic}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="id" label="活动审核" show-overflow-tooltip align="center" width="160">
                <template slot-scope="scope">
                    <el-button :size="tabsize"  type="danger"
                            @click.native.prevent="pass(scope.$index,  scope.row)" >通过
                    </el-button>
                    <el-button :size="tabsize"  type="danger"
                            @click.native.prevent="refuse(scope.$index,  scope.row)" >拒绝
                    </el-button>
                </template>
        </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
    >
    </el-pagination>

    <el-dialog title="评估结论" :visible.sync="conclusionShow" width="500px" :close-on-click-modal="false" center>
        <Conclusion :info="conclusions"></Conclusion>
    </el-dialog>
    <!-- <el-dialog title="" :visible.sync="eyeTableShow" width="1200px" :close-on-click-modal="false" center>
        <EyeTable :kid="kid"></EyeTable>
    </el-dialog> -->

    <el-dialog style="text-align: center;"  title="修改用户信息" :visible.sync="dialoginfo" width="700px" center>
        <el-form label-position="right" label-width="96px" ref='ruleForm' inline :rules='rules' :model="ruleForm">
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="姓名:"  prop="cname">
                          <el-input  v-model="ruleForm.cname" ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="身份证号:" >
                          <el-input  v-model="ruleForm.card" maxlength="18"></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>

              <el-row>
                  <el-col :span="12">
                      <el-form-item label="性别:"  prop="sex" style="margin-left:14px">
                          <el-select v-model="ruleForm.sex" placeholder="请选择"  style="width:174px">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="家长姓名:" prop="pname" >
                          <el-input  v-model="ruleForm.pname" ></el-input>
                      </el-form-item>
                  </el-col> 
              </el-row>

              <el-row>
              <el-col :span="12">
                    <el-form-item label="关系:" style="margin-left:14px" prop="parents">
                          <el-select v-model="ruleForm.parents" placeholder="请选择"  style="width:174px">
                            <el-option
                              v-for="item in options1"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                    </el-form-item>
              </el-col>


              <el-col :span="12">
                      <el-form-item label="家长手机:" prop="phone1"> 
                          <el-input  maxlength="11" oninput="value=value.replace(/[^\d.]/g,'')"  v-model="ruleForm.phone1" ></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>

              <el-row>
                  <el-col :span="12">
                      <el-form-item label="出生日期:"  prop="birthdata" >
                          <el-date-picker  v-model="ruleForm.birthdata" :picker-options="pickerOptions" type="date" placeholder="选择日期" style="width:174px"> </el-date-picker>
                      </el-form-item>
                  </el-col>
              </el-row>
          

              
              <p class="btns">
                <el-button  :size="tabsize" @click="dialoginfo = false" >取消</el-button>
                <el-button  :size="tabsize" type="primary"  @click="onSubmit('ruleForm')">提交</el-button>
              </p>
        </el-form>
        
      </el-dialog>

    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import { initChart, initChart1 } from './echart.js';
    import Conclusion from './Conclusion.vue';
    import EyeTable from './EyeTable.vue';
    import { timestampToTime } from "../../yw/vipuser/formdate";
    export default {
        components: {
            Conclusion,
            EyeTable,
        },
        filters: {
            // 计算宝宝年龄
            comAge(val) {
                let years = Math.floor(val / 12);
                let months = val - (years * 12);
                return years + "岁" + months + "个月"
            },
            formatTimes(val) {
                if(val != null){
                    return dayjs(val).format("YYYY年MM月DD日")
                }else{
                    return "--"
                }
            },
            foramtDate(val) {
                return val && dayjs(val).format('YYYY-MM-MM HH:mm:ss')
            },
        },
        data() {
            return {
                tableData: [],
                currentPage: 1, //默认显示第一页
                pageSize: 10, //默认每页显示10条
                totalNum: '', //总页数
                order: '',
                sort: '',
                jljf:{},
                eyes:require('../../../assets/img/形状\ 1\ 拷贝.png'),
                loading1: true,
                loading2: true,
                loading3: true,
                loading4: true,
                loading5: true,
                info: {},
                actInfo:{},
                recordList: [],
                recordIndex: '',
                recordInfo: {
                    checktime: ''
                },
                recordImgs: [],
                corrList: [],
                ssicaList: [],
                lyslobj: {
                    ages: [],
                    series: []
                },
                qgdobj: {
                    ages: [],
                    series: []
                },
                yzobj: {
                    ages: [],
                    series: []
                },
                achiveData: [],
                achiveInfo: {},
                achiveInfo1: {},
                achObj: {},
                achPage: 1,
                conclusionShow: false, // 评估结论弹出层
                conclusions: {}, // 评估结论
                // eyeTableShow: false, // 屈光档案表格弹出层
                eyetableInfos: [],
                num: 0,
                nums: '',
                dialoginfo: false, //修改用户信息弹出层
                rules: {
                    cname: [
                        { required: true, message: '请输入名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    sex: [
                        {  required: true, message: '请选择性别', trigger: 'change' }
                    ],
                    age: [
                        { required: true,  type: 'number', message: '年龄不能为空'},
                    ],
                    pname: [
                        { required: true, message: '请输入家长姓名', trigger: 'change' }
                    ],
                    phone1: [
                        { required: true, message: '请输入手机号',trigger: 'change'},
                    ],
                    parents: [
                        { required: true, message: '请选择关系', trigger: 'change' }
                    ],
                    birthdata: [
                        { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                    ],
                },
                ruleForm: {
                    cname: '',
                    card: '',
                    sex:'',
                    age:"",
                    pname: '',
                    phone1:'',
                    parents:'',
                    birthdata:"",
                }, 
                options: [{
                        value: '1',
                        label: '男'
                    }, {
                        value: '2',
                        label: '女'
                }],
                options1:[],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
                zjwarn: ''
            }
        },

        props: ['childinfo',"kiid","birthdata"],
        created() {
            this.getchlildInfo()
        },
        methods: {
            formatTime(val) {
                return val ? dayjs(val).format("YYYY-MM-DD") : '--'
            },
            formatAge(val) {
                return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
            },
            // 孩子基本信息
            getchlildInfo() {
                this.loading1 = true;
                this.$api.vip.getVipChlildInfo({ kiid: this.kiid }).then(res => {
                    this.info = { ...res.obj.kidsBasis, ...res.obj.rankingData }
                    this.loading1 = false;
                })
            },
            // 档案
            getRecords() {
                this.$api.vip.getVipChlildRecord({ kiid: this.kiid, num: this.num }).then(res => {
                    this.zjwarn = '视力发育正常，暂无风险，继续保持综合防控。'
                    if (res.status == 1) {
                        if( res.obj.id != undefined ){
                          this.nums = res.nums;
                          this.actInfo = res.obj;
                          console.log(this.actInfo)
                          if(this.actInfo.zjwarn != '') {
                            this.zjwarn = this.actInfo.zjwarn
                          }
                          // if(this.actInfo.ljpt == null) {
                          //   this.actInfo.屈光度左 = '-'
                          //   this.actInfo.柱镜左 = '-'
                          //   this.actInfo.散光轴位左 = '-'
                          //   this.actInfo.屈光度右 = '-'
                          //   this.actInfo.柱镜右 = '-'
                          //   this.actInfo.散光轴位右 = '-'
                          //   this.actInfo.瞳距 = '-'
                          //   this.actInfo.瞳高 = '-'
                          // }
                          var arr = [];
                          for (var i = 0 ; i < res.obj.img.length; i++){
                            arr.push( res.obj.img[i].imgpath )
                          }
                          this.recordImgs = arr;
                        }else{
                          this.nums = 0;
                        }
                    }
                    this.loading2 = false;
                })
            },
            // 上一个档案
            recLeftClick() {
                if (this.num == 0) return;
                this.num--;
                this.getRecords();
            },
            // 下一个档案
            recRightClick() {
                if ( (this.num + 1) >= this.nums) return;
                this.num++;
                this.getRecords();
            },
              // 上一个镜方
            recLeftClick1() {
                if (this.num <= 0) return;
                this.num--;
                this.getRecords();
            },
            // 下一个镜方
            recRightClick1() {
                if (this.num >= this.nums) return;
                this.num++;
                this.getRecords();
            },
            // 查看去逛档案表格
            lookTable() {
                // this.eyeTableShow = true;
                this.$router.push({
                    path: "/share",
                    query: { kiid: this.kiid ,birthdata:this.info.birthdata},
                })
            },
            // 新增档案
            add(){
              this.$router.push({
                path: "/information",
                query: { kiid: this.kiid ,birthdata:this.info.birthdata},
              })
            },
             // 修改档案表格
            edit( ){
                console.log("id==>", this.actInfo.id,'kiid=', this.kiid);
                this.$router.push({
                path: "./oldinformation",
                query: { id: this.actInfo.id,kiid:this.kiid },
                });
            },
            // 矫正记录
            getCorrs() {
                this.loading3 = true;
                this.$api.vip.getVipChlildCorrs({ kiid: this.kiid }).then(res => {
                    this.corrList = res.obj
                    this.loading3 = false;
                })
            },
            // 问卷评估记录
            getSsicas() {
                this.loading4 = true;
                this.$api.vip.getVipChlildSsica({ kiid: this.kiid }).then(res => {
                    this.ssicaList = res.obj
                    this.loading4 = false;
                })
            },
            // 查看评估结论
            lookConclusion(obj) {
                this.conclusionShow = true;
                this.conclusions = obj;
            },
            // 视力趋势折线图
            get_chart_yz() {
                this.$api.vip.get_chart_yz({ kiid: this.kiid }).then(res => {
                    this.yzobj = {
                        ages: res.obj.age,
                        series: [
                            {
                                name: '左眼',
                                type: 'line',
                                smooth: true,
                                data: res.obj.la,
                                itemStyle: {
                                    color: '#698edc',
                                },
                                lineStyle: {
                                    color: '#698edc',
                                    width: 3,
                                },
                                areaStyle: {
                                    origin: 'start',
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#698edc' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#fff' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                }
                            },
                            {
                                name: '右眼',
                                type: 'line',
                                smooth: true,
                                data: res.obj.ra,
                                itemStyle: {
                                    color: '#6dbf7d',
                                },
                                lineStyle: {
                                    color: '#6dbf7d',
                                    width: 3,
                                },
                                areaStyle: {
                                    origin: 'start',
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#6dbf7d' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#fff' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                }
                            },
                        ]
                    }
                    initChart('yzChart', '毫米', ['左眼', '右眼', '基准值'], this.yzobj.ages, this.yzobj.series, 30, 16)
                })
            },
            // 视力趋势折线图
            get_chart_ly() {
                this.$api.vip.get_chart_ly({
                    kiid: this.kiid
                }).then(res => {
                    this.lyslobj = {
                        ages: res.obj.age,
                        series: [
                            {
                                name: '左眼',
                                type: 'line',
                                smooth: true,
                                data: res.obj.lyl,
                                itemStyle: {
                                    color: '#698edc',
                                },
                                lineStyle: {
                                    color: '#698edc',
                                    width: 3,
                                },
                                areaStyle: {
                                    origin: 'start',
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#698edc' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#fff' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                }
                            },
                            {
                                name: '右眼',
                                type: 'line',
                                smooth: true,
                                data: res.obj.lyr,
                                itemStyle: {
                                    color: '#6dbf7d',
                                },
                                lineStyle: {
                                    color: '#6dbf7d',
                                    width: 3,
                                },
                                areaStyle: {
                                    origin: 'start',
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#6dbf7d' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#fff' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                }
                            },
                        ]
                    }
                    initChart('lyslChart', '度', ['左眼', '右眼', '基准值'], this.lyslobj.ages, this.lyslobj.series, '5.3', '4.0')
                })
            },
            // 视力趋势折线图
            get_chart_qgd() {
                this.$api.vip.get_chart_qgd({
                  kiid: this.kiid
                }).then(res => {
                    this.qgdobj = {
                        ages: res.obj.age,
                        series: [
                            {
                                name: '左眼',
                                type: 'line',
                                smooth: true,
                                data: res.obj.ls,
                                itemStyle: {
                                    color: '#698edc',
                                },
                                lineStyle: {
                                    color: '#698edc',
                                    width: 3,
                                },
                                areaStyle: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#698edc' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#fff' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    },
                                    origin: 'start',
                                }
                            },
                            {
                                name: '右眼',
                                type: 'line',
                                smooth: true,
                                data: res.obj.rs,
                                itemStyle: {
                                    color: '#6dbf7d',
                                },
                                lineStyle: {
                                    color: '#6dbf7d',
                                    width: 3,
                                },
                                areaStyle: {
                                    origin: 'start',
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: '#6dbf7d' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: '#fff' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                }
                            },
                        ]
                    }
                    initChart('qgdChart', '度', ['左眼', '右眼', '基准值', '理论值'], this.qgdobj.ages, this.qgdobj.series, 3.00, -15.00)
                })
            },
            // 成就记录
            getAchivements() {
                this.$api.vip.getVipChlildCjs({
                    kiid: this.kiid
                }).then(res => {
                    if (res.obj.length > 0) {
                        this.achiveData = res.obj;
                        for (let i = 0; i < this.achiveData.length; i++) {
                            let arr = JSON.parse(this.achiveData[i].jsondata);
                            this.$set(this.achiveData[i], 'list', arr)
                        }
                        this.achiveInfo = this.achiveData[this.achiveData.length - 1];
                        // console.log( this.achiveInfo )
                    }
                    this.initAchChart();
                })
            },
            initAchChart() {
                let arr = this.achiveData;
                let xdata = [], ydata = [];
                for (let i = 0; i < arr.length; i++) {
                    xdata.push(arr[i].cweeks)
                    ydata.push(arr[i].acmvalue)
                }
                this.achObj = {
                    xAxis: xdata,
                    series: [
                        {
                            name: '成就点',
                            type: 'line',
                            smooth: true,
                            data: ydata,
                            itemStyle: {
                                color: '#698edc',
                            },
                            lineStyle: {
                                color: '#698edc',
                                width: 3,
                            },
                            areaStyle: {
                                origin: 'start',
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#698edc' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#fff' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }
                            }
                        },
                    ]
                }
                initChart1('achiveChart', '', '', this.achObj.xAxis, this.achObj.series)
            },
            initPage() {
                this.getAList();
                this.getchlildInfo();
                this.getRecords();
                this.getCorrs();
                this.getSsicas();
                this.getAchivements();
                this.get_chart_yz();
                this.get_chart_ly();
                this.get_chart_qgd();

            },
            getAList(){
                this.loading5 = true
              console.info( "getAAList" );
              this.$api.vip.getAList({
                'kiid': this.kiid,
                'jPage.pageNum': this.currentPage, //当前页码
                'jPage.pageSize': this.pageSize, //当前页面大小
                'jPage.sort': this.sort, //排序方式
                'jPage.order': this.order, //排序字段
              }).then(res => {
                console.info('活动报名列表数据=>', res)
                this.tableData = res.obj.list
                this.totalNum = res.obj.totalPage // 总页数
                this.tableData.length = res.obj.totalRow //总条数
                this.currentPage = res.obj.pageNumber //当前页码数
                this.loading5 = false
              })
            },
            //活动通过
            pass(index,row) {
                    this.$confirm('确定通过该活动？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.loading5 = true
                        this.$api.vip.getDown({
                            id: row.id,
                            status: 20
                        }).then(res => {
                            // console.log(res)
                            if(res.status == 1) {
                                this.$alertSuccess('活动已通过')
                                this.getAList()
                            } else {
                                this.$alertError(res.msg)
                            }
                        })
                    }).catch(() => {

                    });
            },
            //活动拒绝
            refuse(index,row){
                this.$confirm('确定拒绝该活动？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        this.loading5 = true
                        this.$api.vip.getDown({
                            id: row.id,
                            status: 30
                        }).then(res => {
                            // console.log(res)
                            if(res.status == 1) {
                                this.$alertSuccess('活动已拒绝')
                                this.getAList()
                            } else {
                                this.$alertError(res.msg)
                            }
                        })
                    }).catch(() => {

                    });
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
               // 分页大小
            handleSizeChange(val) {
                this.loading = true;
                this.pageSize = val;
                this.currentPage = 1; // 选择分页大小后跳转第一页
                //   this.postlist();
            },
            // 第几页
            handleCurrentChange(val) {
                this.loading = true;
                this.currentPage = val;
                //   this.postlist();
            },

            //获取关系
            parents(){
                this.$api.vipuser.parents({
                })
                    .then((res) => {
                    this.options1=res.obj;
                    })
                    .catch((error) => {
                    console.log(error);
                });
            },
            userinfo() {
                this.parents();
                this.getById1()
                this.dialoginfo = true
            },
            getById1() {
                this.$api.vip.getById1({
                    id: this.kiid
                }).then(res => {
                    // console.log(res)
                    this.ruleForm.cname = res.obj.cname
                    this.ruleForm.card = res.obj.card
                    this.ruleForm.sex = (res.obj.sex).toString()
                    this.ruleForm.pname = res.obj.jzname
                    this.ruleForm.phone1 = res.obj.phone
                    this.ruleForm.parents = res.obj.parents
                    this.ruleForm.birthdata = res.obj.birthdata
                })
            },
            onSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if(valid) {
                        this.$api.vip.getUpdate1({
                            id: this.kiid,
                            cname: this.ruleForm.cname,
                            card: this.ruleForm.card,
                            sex: this.ruleForm.sex,
                            pname: this.ruleForm.pname,
                            phone: this.ruleForm.phone1,
                            parents: this.ruleForm.parents,
                            birthdata: timestampToTime(this.ruleForm.birthdata)
                        }).then(res=>{
                            // console.log(res)
                            if(res.status == 1) {
                                this.dialoginfo = false
                                this.$alertSuccess(res.msg)
                                this.$emit('useInfo')
                                this.getchlildInfo()
                            } else {
                                this.$alertError(res.msg)
                            }
                        })
                    }
                })                
            }
        },
        mounted() {
            this.initPage();
        },
        // 监听尺寸按钮变化 改变表格大小
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
    }
</script>

<style scoped>
    .container {
        width: 100%;
        height: 100%;
    }

    /* .eye {
        width: 100%;
        height: 50px;
        border-radius: 50%;
        border: blue 1px solid;
    } */
    .child1 {
        width: 100%;
        height: 800px;
        margin-left: -15px;
        margin-right: -15px;
    }

    .eyes {
        width: 100%;
        height: 100px;
        /* background: url('../../../assets/img/形状\ 1\ 拷贝.png') no-repeat; */
        display: flex;
        margin: 20px 0 !important;
    }

    .tu{
        width: 80% !important;
    }

    .tongju {
        margin-top: 22%;
    }

    .tonggao {
        margin-top: 40%; 
    }

    .margin10 .el-row {
        margin: 10px 0;
        display: flex;
        align-items: center;
    }


    .child-info {
        padding: 10px 20px;
    }

    .child-info>.rows {
        line-height: 20px;
        margin: 10px 0;
        min-height: 20px;
    }
    .label{
    white-space: nowrap;
    }
    .value {
        color: #666666;
    }

    .mb20 {
        margin-bottom: 10px;
    }

    .pd20 {
        padding: 20px;
    }

    .pdr30 {
        padding-right: 30px;
    }

    .height300 {
        height: 400px;
        /* width:99.45%; */
    }

    .height30 {
        height: 30px;
        line-height: 30px;
    }

    .head-title {
        width: 100%;
    }

    .head-title>span {
        font-weight: bold;
        font-size: 16px;
    }

    .head-title>p>span {
        font-weight: bold;
        font-size: 16px;
    }

    .head-title>div>span {
        font-weight: bold;
        font-size: 16px;
    }

    .dangan-title {
        display: flex;
        justify-content: space-between;
    }

    .el-divider--horizontal {
        margin: 0;
    }

    #lyslChart,
    #qgdChart,
    #yzChart,
    #achiveChart {
        width: 100%;
        height: 340px;
        background: #fff;
    }

    .chart-title {
        height: 30px;
        font-size: 16px;
        font-weight: bold;
        background-color: #fff;
        padding-top:10px;
        padding-left: 10px;
    }

    .achive-titles {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;
        height: 30px;
        background-color: #fff;
    }

    .achive-titles>span:first-of-type {
        font-size: 16px;
        font-weight: bold;
    }

    .achive-view {
        width: 100%;
    }

    .user-img {
        display: flex;
        align-items: center;
    }

    .user-img>div {
        font-size: 16px;
        font-weight: bold;
        margin-right:1.2vw;
    }

    .user-img>span {
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        padding: 5px 6px;
        border-radius: 3px;
        background: #6dbf7d;
        color: #fff;
    }

    .sex {
        white-space: nowrap;
        font-size: 12px;
        color: #666;
        margin-right: 10px;
    }

    .address,
    .school {
        white-space: nowrap;
        font-size: 10px;
        margin-right: 10px;
    }

    .record {
        /* border: solid 0.05vw #6dbf7d; */
        position: relative;
    }

    .bold {
        font-weight: bold;
    }

    .record-left-btn {
        width: 20px;
        height: 100px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url('../../../assets/img/左翻.png') no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
    .record-right-btn {
        width: 20px;
        height: 100px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url('../../../assets/img/右翻.png') no-repeat center;
        background-size: cover;
        cursor: pointer;
    }

    .dian {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: linear-gradient(148deg, rgba(98,235,243,0.31) 0%, #18A9DD 100%);
    }

    .dian_white {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ffffff;
    }

    .dates {
        font-size: 12px;
        color: #666666;
        flex-shrink: 0;
    }


    .histroy-list {
        height: 300px;
        overflow-y: scroll;
    }

    .histroy-list li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .histroy-list1 {
        height: 300px;
        overflow-y: scroll;
        margin: 20px;
    }

    .histroy-list1 li {
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
    }

    .histroy-list>p {
        height: 20px;
        font-size: 12px;
        color: #666;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }


    .hight600{
         height: 1220px;
    }

    .bgwhite {
        background: #fff;
        /* margin: 10px; */
    }

    .achive-view {
        margin: 20px;
    }

    .achive-title {
        display: flex;
        height: 50px;
        align-items: center;
    }

    .achive-title-left {
        display: flex;
        height: 100%;
        align-items: center;
    }

    .achive-title-left>div {
        margin-right: 20px;
    }

    .achive-num {
        display: flex;
        width: 180px;
        height: 50px;
        align-items: center;
        font-weight: bold;
        font-weight: bold;
        background-image: linear-gradient(-90deg,
                #ffffff 0%,
                #a3e6b0 100%),
            linear-gradient(#ffc143,
                #ffc143);
        background-blend-mode: normal,
            normal;
        border-radius: 28px;
    }

    .achive-ranking {
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #69bd79;
        background-color: #ffffff;
        border-radius: 50%;
        margin: 0 20px 0 3px;
    }

    .achive-list {
        padding: 10px;
        margin-top: 20px;
        margin-right: 30px;
        height: 200px;
    }

    .achive-list .el-row {
        height: 30px;
        line-height: 30px;
    }

    .achive-page {
        width: 100%;
        height: 30px;
        text-align: center;
    }

    .achive-page button {
        border: none;
        outline: none;
        width: 24px;
        height: 20px;
        background-color: #f2f3f5;
        border-radius: 3px;
        margin: 0 5px;
        color: #333333;
        cursor: pointer;
    }

    .ach-active {
        background-color: #698edc !important;
        border-radius: 3px !important;
        border: solid 1px #698edc !important;
        color: #ffffff !important;
    }

    /deep/.el-dialog__body {
        padding-top: 0;
    }

    /deep/.el-link.el-link--success {
        color: #6fc07f;
    }

    .fonthidden {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .flex{
        display: flex;
    }
        
    .flex1{
        display: flex;
        justify-content: space-between;
    }
    .high{
        max-height: 480px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .width1 {
        width: 10%;
    }
    .width4 {
        width: 40%;
    }
    .width3 {
        width: 30%;
    }
    .width2 {
        width: 20%;
    }
    .dsta{
         color: rgba(211, 39, 15, 1);
    }
    .wsta{
        color: rgb(142, 142, 142);
    }
    .ysta{
        color: rgb(57, 165, 18);
    }
</style>