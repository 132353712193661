//1.将时间戳转换成显示格式
export function timestampToTime(timestamp) {
    if(timestamp <=0){
        return "- -"
    }
    var date = new Date(timestamp);
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
	var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate());
	return Y+M+D;

}


export function Age(timestamp) {
    if(timestamp <=0){
        return "- -"
    }
    var date = new Date(timestamp);
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) ;
	return Y+M;
}

export function formatAge(val) {
    return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
}