<template>
  <div class="chat-box">
    <div class="msg-box" ref="msg-box">
        <div
          v-for="(item,index) in list" :key="index"
          class="msg"
          :style="item.outid == -1?'flex-direction:row-reverse':''"
        >
          <div class="user-head" :id="item.id">
            <img class="head" :src="item.icon" alt="" />
          </div>
          <div class="user-msg">
            <span @click="msgclick(item.content)"
              :style="item.outid == -1? 'float: right;':''"
              :class="item.outid == -1? 'right':'left'"
              v-html="item.content" 
            ></span>
          </div>
        </div>
    </div>
    <div class="input-box">
      <input type="text" ref="sendMsg" v-model="contentText" @keyup.enter="sendText()" />
      <div class="btn" :class="{['btn-active']:contentText}" @click="sendText()">发送</div>
    </div>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      userId: -1, //当前用户ID
      list: [], //聊天记录的数组
      contentText: "", //input输入的值
      pageNum: 1,
      pageSize: 20,
      scrollFlag:true,  // 是否开启固定底部
      msgLoad:true,     // 是否允许加载数据
      initId: 0,        // 初始顶部消息id
      topId:0,           // 当前顶部消息id
      urlReg:/^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i,
      msgTimer: null,
      puid: 0
    };
  },
  props: ['uid'],
  created() {  
    console.info( 'service.uid=>' ,this.uid )
  },
  destroyed(){
    clearTimeout(this.msgTimer)
    this.msgTimer = null;
  },
  mounted() {
    let _this = this;
    window.addEventListener("scroll", this.handleScroll, true); // 添加滚动条监听事件
    this.getMsgList( this.pageNum, this.pageSize , 'desc');
    this.msgTimer = setInterval(function(){
        _this.getMsgList( 1, 10, 'desc'); // 预计会有问题 ***
    }, 1000 * 3 )
    this.scrollBottm();   // 页面数据加载完成滚动条底部
  },
  methods: {
    handleScroll(){ // 滚动条监听
        var container = this.$el.querySelector(".msg-box");

        this.scrollFlag=false // 暂停固定底部
        if( container.scrollTop == 0 ){ // 顶部，加载历史消息
          if( this.msgLoad ){  // 是否允许加载（是否正在加载中）
            this.msgLoad = false;
            this.pageNum = this.pageNum + 1;
            this.getMsgList( this.pageNum , this.pageSize , 'asc');
          }
        }

        if( ( container.scrollTop + container.clientHeight ) >= container.scrollHeight  ){ // 滚动条顶部距离 + 可视高度 >=  内容高度， 视为页面底部 -> 开启固定底部
          this.scrollFlag=true;
        }
    },
    getMsgList( pageNum ,pageSize , sort){ //获取历史聊天消息列表
      if( this.list.length > 1 ){   // 更新当前顶部消息id
        this.topId = this.list[1].id
      }
      this.$api.message.kflist( {
        'jPage.pageNum': pageNum,
        'jPage.pageSize': pageSize,
        'puid': this.uid
      } )
      .then(res => {
        if( sort === 'desc' ){
          for( var i = (res.obj.list.length -1) ; i >= 0; i -- ){
            this.initItem( res.obj.list[i] )
          }
        }else{
          for( var i = 0 ; i < res.obj.list.length; i ++ ){
            this.initItem( res.obj.list[i] )
          }
        }

        if( this.initId == 0 ){
          this.initId = res.obj.list[ res.obj.list.length - 1 ].id
        }

        if( this.scrollFlag ){
          this.scrollBottm();
        }
        if( sort === 'asc' ){
          this.scrollNow()
        }
        this.msgLoad = true;
      });
    },
    initItem( item ){
      let boo = true;
      this.list.forEach(item1 => {  // 验证是否重复消息
        if( item1.id == item.id ){
          boo = false;
        }
      });
      if( boo ){
        item.icon = item.outid == -1 ? require('../../../assets/img/msg_kf.png') : require('../../../assets/img/msg_user.png')

        if( this.list.length == 0 || item.id > this.initId ){
          this.list.push( item )
        }else{
          this.list.unshift( item )
        }
      }
    },
    scrollBottm() {//滚动条到底部
      this.$nextTick(() => {
        var container = this.$el.querySelector(".msg-box");
        container.scrollTop = container.scrollHeight;
      })
    },
    scrollNow() {
      this.$nextTick(() => {//滚动停留当前位置
        var container = this.$el.querySelector(".msg-box");
        var topMsg = document.getElementById( this.topId );
        if( topMsg != null ){
          container.scrollTop = topMsg.getBoundingClientRect().top - container.clientHeight ;
        }
      })
    },
    //发送聊天信息
    sendText( msg ) {
      let _this = this;
      _this.$refs["sendMsg"].focus();
      if( msg != null && msg != '' && msg != undefined ){
        _this.contentText = msg;
      }
      if (!_this.contentText) {
        return;
      }
      // 发送消息
      this.$api.message.kfsend( {
        'puid': this.uid,
        'content': _this.contentText
      } )
      .then(res => {
        this.scrollFlag = true;
        this.getMsgList(1, 10 , 'desc'); // ***存在问题，当客服和用户同时发送消息时，可能消息不显示
      });
      _this.contentText = "";   // 输入框置为空      
    },
    msgclick( msg ){
      if( this.urlReg.test( msg ) ){
        window.open( msg, '_blank'); 
      }
    },
    inpFocus(){
      this.scrollBottm()
    }
  }
};
</script>
 
<style lang="less" scoped>
.chat-box {
  margin: 0 auto;
  background: #fafafa;
  position: absolute;
  height: 100%;
  width: 35%;

  .msg-box {
    position: absolute;
    height: calc(100% - 4.2rem);
    width: 100%;
    // margin-top: 3rem;
    overflow-y: scroll;
    .msg {
      width: 85%;
      min-height: 2.5rem;
      margin: 1rem 0.5rem;
      position: relative;
      display: flex;
      justify-content: flex-start !important;
      .user-head {
        min-width: 2.5rem;
        width: 20%;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background: #f1f1f1;
        display: flex;
        justify-content: center;
        align-items: center;
        .head {
          width: 1.2rem;
          height: 1.2rem;
        }
        // position: absolute;
      }
      .user-msg {
        width: 80%;
        // position: absolute;
        word-break: break-all;
        position: relative;
        z-index: 5;
        span {
          display: inline-block;
          padding: 0.5rem 0.7rem;
          border-radius: 0.5rem;
          margin-top: 0.2rem;
          font-size: 0.88rem;
        }
        .left {
          background: white;
          animation: toLeft 0.5s ease both 1;
        }
        .right {
          background: #53a8ff;
          color: white;
          animation: toright 0.5s ease both 1;
        }
        @keyframes toLeft {
          0% {
            opacity: 0;
            transform: translateX(-10px);
          }
          100% {
            opacity: 1;
            transform: translateX(0px);
          }
        }
        @keyframes toright {
          0% {
            opacity: 0;
            transform: translateX(10px);
          }
          100% {
            opacity: 1;
            transform: translateX(0px);
          }
        }
      }
    }
  }
  .input-box {
    padding: 0 0.5rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    background: #fafafa;
    box-shadow: 0 0 5px #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      height: 2.3rem;
      display: inline-block;
      width: 100%;
      padding: 0.5rem;
      border: none;
      border-radius: 0.2rem;
      font-size: 0.88rem;
    }
    .btn {
      height: 2.3rem;
      min-width: 4rem;
      background: #e0e0e0;
      padding: 0.5rem;
      font-size: 0.88rem;
      color: white;
      text-align: center;
      border-radius: 0.2rem;
      margin-left: 0.5rem;
      transition: 0.5s;
    }
    .btn-active {
      background: #409eff;
    }
  }
}
</style>