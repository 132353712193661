<template>
    <div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.95)">
        <el-table :data="eyeData" :span-method="objectSpanMethod" size="small" border :stripe="false"
            :row-class-name="rowClass" style="width: 100%; margin-top: 20px">
            <el-table-column prop="date" label="日期" align="center" width="90">
                <template slot-scope="scope">
                    <span>{{ scope.row.checktime }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" align="center">
                <template slot-scope="scope">
                    <span>{{ formatAge(scope.row.age) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="眼别" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.type }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="" label="裸眼" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.裸眼视力右 || scope.row.裸眼视力左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="矫正" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.矫正视力右 || scope.row.矫正视力左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="验光屈光度" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.屈光度右 || scope.row.屈光度左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="理论屈光度" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.age }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="眼轴" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.眼轴长度右 || scope.row.眼轴长度左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="临界眼轴" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.眼轴长度右 || scope.row.眼轴长度左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="轴余" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.眼轴长度右 || scope.row.眼轴长度左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="角膜曲率K1" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.角膜曲率右k1 || scope.row.角膜曲率左k1 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="角膜曲率K2" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.角膜曲率右k2 || scope.row.角膜曲率左k2 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="散光" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.散光度数右 || scope.row.散光度数左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="轴位" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.散光轴位右 || scope.row.散光轴位左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="眼压" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.散光轴位右 || scope.row.散光轴位左 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="瞳距" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.PD瞳距 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="瞳高" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.瞳高 }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="身高" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.height }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="体重" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.weight }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="age" label="备注" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.remark }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
    import dayjs from 'dayjs';
    export default {
        props: ['kid'],
        data() {
            return {
                loading: false,
                eyeData: [],
                spanMethodOption: {}
            }
        },
        mounted() {
            this.get_table_list()
        },
        methods: {
            formatDate(row, col, cellvalue) {
                return dayjs(cellvalue).format('YYYY-MM-DD')
            },
            formatAge(val) {
                return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
            },
            rowClass({ row, rowIndex }) {
            },
            get_table_list() {
                this.$api.vip.get_vip_table_list({ kid: this.kid }).then(res => {
                    console.log("res=>",res)
                    let array = res.obj.obj
                    let arr = [];
                    array.map(item => {
                        arr.push({
                            type: '右',
                            ...item.右眼
                        })
                        arr.push({
                            type: '左',
                            ...item.左眼
                        })
                    })
                    this.eyeData = arr;
                })
            },
            objectSpanMethod({ row, column, rowIndex, columnIndex }) {
                if (columnIndex === 0 || columnIndex === 1 || columnIndex > 14) {
                    if (rowIndex % 2 === 0) {
                        return {
                            rowspan: 2,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            }
        },
    }
</script>
<style scoped>
    /deep/ .el-table {
        .version-table th {
            background-color: #797979;

            .cell {
                color: #fff;
            }
        }

    }
    .even-row {
        background-color: #fff;
    }

    .odd-row {
        background-color: rgba(76, 75, 75, 0.04);
    }

    .red {
        color: #ed5327;
    }
</style>